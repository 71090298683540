import { template as template_602be682d9704c9bb0d66f8a8d0e472e } from "@ember/template-compiler";
const SidebarSectionMessage = template_602be682d9704c9bb0d66f8a8d0e472e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
