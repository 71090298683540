import { template as template_10e6481608724ba082f3f8b3041b48d5 } from "@ember/template-compiler";
const FKText = template_10e6481608724ba082f3f8b3041b48d5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
